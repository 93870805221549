// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body .YardProductDetails-period-picker-container {
    padding-top: 10%;
}

body .YardProductDetails-selector-title {
    font-size: 70%;
    font-weight: 600;
    padding-bottom: 20px;
}

body .YardProductDetails-billing-period-text {
    font-size: 60%;
    font-weight: 600;
    color: #707172;
}

body .YardProductDetails-button-buy-now {
    margin-top: 5%;
    background-color: #FC4818;
    border-radius: 12px;
    width: 100%;
    color: white;
}

body .YardProductDetails-grid-start-date {
    display: grid;
    align-items: end;
}

body .YardProductDetails-mobile-date-pícker {
    width: 100%;
    border-radius: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/ProductDetailsComponents/PeriodPicker/PeriodPicker.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,oBAAoB;AACxB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,yBAAyB;IACzB,mBAAmB;IACnB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,mBAAmB;AACvB","sourcesContent":["body .YardProductDetails-period-picker-container {\n    padding-top: 10%;\n}\n\nbody .YardProductDetails-selector-title {\n    font-size: 70%;\n    font-weight: 600;\n    padding-bottom: 20px;\n}\n\nbody .YardProductDetails-billing-period-text {\n    font-size: 60%;\n    font-weight: 600;\n    color: #707172;\n}\n\nbody .YardProductDetails-button-buy-now {\n    margin-top: 5%;\n    background-color: #FC4818;\n    border-radius: 12px;\n    width: 100%;\n    color: white;\n}\n\nbody .YardProductDetails-grid-start-date {\n    display: grid;\n    align-items: end;\n}\n\nbody .YardProductDetails-mobile-date-pícker {\n    width: 100%;\n    border-radius: 12px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
