import * as React from "react";

const ProductDetailsContext = React.createContext();
import { useYardProductInformation } from "./YardProductInformationContext";
import Cookies from "universal-cookie";

export const useProductDetail = () => React.useContext(ProductDetailsContext);

export const ProductDetailProvider = ({ children }) => {
  //STATES------------------------------------------------------------
  const { handleCloseModal, itemObject } = useYardProductInformation();
  const [productSelected, setProductSelected] = React.useState("");
  const [subTotal, setSubTotal] = React.useState(0);
  const [spots, setSpots] = React.useState(0);
  const [rangeOfAgreementDate, setRangeOfAgreementDate] = React.useState({
    startDate: new Date(),
    endDate: new Date(new Date().getTime() + 6 * 24 * 60 * 60 * 1000),
  });
  const cookies = new Cookies();
  const [itemProducts, setItemProducts] = React.useState({});

  //FUNCTIONS------------------------------------------------------------
  function handleSetSubTotalChange() {
    if (!itemProducts.Services) return;
    const productList = itemProducts.Services;
    productList.forEach((product) => {
      if (product.Frequency === productSelected) {
        const DailyPricesList = product.Regular;
        let lowestPrice = 0;

        DailyPricesList.forEach((price) => {
          if (price.Cost < lowestPrice || lowestPrice === 0) {
            lowestPrice = price.Cost;
            const priceToFloat = parseFloat(lowestPrice);
            setSubTotal(priceToFloat * (spots === 0 ? 1 : spots));
          }
        });
      }
    });
  }

  function handleChangeProductSelected(e) {
    setProductSelected(e);
    return e === "Daily" ? setSpots(0) : setSpots(1);
  }

  function handleChangeNumberOfSpots(e) {
    if (e.target.value === 0) {
      setSpots(0);
      return;
    }
    const newValue = e.target.value.trim();
    const validNumber = /^[0-9]*$/;
    if (
      newValue === "" ||
      (validNumber.test(newValue) &&
        parseInt(newValue) >= 0 &&
        parseInt(newValue) <= itemObject.ItemAvailables)
    ) {
      setSpots(newValue === "" ? "" : parseInt(newValue));
    }
  }

  function handleChangeStartDate(date) {
    const dateSelected = new Date(date);
    let endDate;

    if (productSelected === "Daily") {
      endDate = new Date(dateSelected.getTime() + 6 * 24 * 60 * 60 * 1000); // 7 días después
    } else if (productSelected === "Weekly") {
      const currentDay = dateSelected.getDay();
      const daysUntilSaturday = 6 - currentDay; // 6 porque sábado es el día 6 en getDay()
      endDate = new Date(
        dateSelected.getTime() + daysUntilSaturday * 24 * 60 * 60 * 1000
      );
    } else {
      const newDate = new Date(dateSelected);
      newDate.setMonth(newDate.getMonth() + 1);
      endDate = newDate;
    }

    setRangeOfAgreementDate({
      startDate: dateSelected,
      endDate,
    });
  }

  function handleCheckIfYardProductsExist() {
    const itemProductObject = GetYardProductFromLocalStorage();

    if (!itemProductObject) {
      handleSaveNewProduct();
      return; // Termina la función si no hay productos en el almacén local
    }

    const itemIdCodeName = itemProducts.ItemId.CodeName;
    const startDateFormatted = FormatDate(rangeOfAgreementDate.startDate);
    const endDateFormatted = FormatDate(rangeOfAgreementDate.endDate);

    let foundProduct = false;

    for (const itemProduct of itemProductObject) {
      const product = itemProduct.ProductsChosen;
      if (
        product.YardSelected.CodeName === itemIdCodeName &&
        itemProduct.StartDate === startDateFormatted &&
        itemProduct.EndDate === endDateFormatted
      ) {
        handleRewriteProduct();
        foundProduct = true;
        break; // Termina el bucle una vez que se encuentra un producto
      }
    }

    if (!foundProduct) {
      handleSaveNewProduct();
    }
  }

  function FormatDate(date) {
    return date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
  }

  function GetYardProductFromLocalStorage() {
    const YardProducFromLocalStorage = localStorage.getItem("yardProducts");
    return JSON.parse(YardProducFromLocalStorage);
  }

  const handleRewriteProduct = () => {
    const yardProductObject = GetYardProductFromLocalStorage();
    yardProductObject.forEach((yardProduct) => {
      if (
        yardProduct.ProductsChosen.YardSelected.CodeName ===
          itemProducts.ItemId.CodeName &&
        yardProduct.StartDate === FormatDate(rangeOfAgreementDate.startDate)
      ) {
        yardProduct.ProductsChosen.NumberOfSpots += spots;
      }
    });

    localStorage.setItem("yardProducts", JSON.stringify(yardProductObject));
  };

  function handleSaveNewProduct() {
    const productList = itemProducts.Services;
    let prdSelectedToSubmit = {};

    productList.forEach((product) => {
      if (product.Frequency === productSelected) {
        prdSelectedToSubmit = product;
      }
    });

    const newProduct = {
      StartDate: FormatDate(rangeOfAgreementDate.startDate),
      EndDate: FormatDate(rangeOfAgreementDate.endDate),
      itemType: itemProducts.ItemType,
      ProductsChosen: {
        NumberOfSpots: spots,
        YardSelected: itemProducts.ItemId,
        PriceChosen: prdSelectedToSubmit,
      },
    };

    let yardProductFromLocalStorage = GetYardProductFromLocalStorage();

    if (!yardProductFromLocalStorage) {
      yardProductFromLocalStorage = [];
    }
    yardProductFromLocalStorage.push(newProduct);
    localStorage.setItem(
      "yardProducts",
      JSON.stringify(yardProductFromLocalStorage)
    );
  }

  function hanndleButtonBuyNow() {
    handleCheckIfYardProductsExist();
    handleCloseModal();
    handlePushState("landing-page/cart-products");
  }
  function handlePushState(newUrl) {
    const baseUrl = window.location.origin; // Obtiene la URL base (http://localhost:8080)
    const fullUrl = `${baseUrl}/${newUrl}`; // Combina la URL base con la nueva URL, asegurándote de incluir la barra diagonal

    window.history.pushState({}, null, fullUrl);
  }

  //USE EFFECTS------------------------------------------------------------
  React.useEffect(() => {
    if (itemObject) {
      if (itemObject.ItemType === "Chassis") {
        setProductSelected("Weekly");
        setSpots(1);
      } else {
        setProductSelected("Daily");
      }
      setItemProducts(itemObject);
    }
  }, [itemObject]);

  React.useEffect(() => {
    handleSetSubTotalChange();
  }, [productSelected, spots, itemProducts]);

  React.useEffect(() => {
    handleChangeStartDate(rangeOfAgreementDate.startDate);
  }, [productSelected]);

  return (
    <ProductDetailsContext.Provider
      value={{
        itemObject,
        subTotal,
        spots,
        productSelected,
        rangeOfAgreementDate,
        handleSetSubTotalChange,
        handleChangeProductSelected,
        handleChangeNumberOfSpots,
        handleChangeStartDate,
        hanndleButtonBuyNow,
      }}
    >
      {children}
    </ProductDetailsContext.Provider>
  );
};
